#pluswrap {
    position: fixed;
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    top: 0;
  }
  
  .plus {
    display: flex;
    margin: 0 auto;
  }

  .agtName {
    padding-left: 10px;
  }

  .cb {
    float:left;
    margin-right: 10px;
  }

  ion-button {
    font-weight: 700 !important;
  }

  ion-item.q{ 
    max-width: 450px;
  }

  img.agt_photo {
    max-height: 300px;
  }

  img.enlarged_prior {
    width: 200px !important;
  }

  img.agt_prior_photo {
    max-height: 200px;
  }

 

  